import React, { useState, useEffect } from "react";
import { SubmitBtn } from "../../Components/Card/Card";
import { useSelector, useDispatch } from "react-redux";
import { saveBlogImages } from "../../redux/action/blogAction";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import FormLoader from "../../Components/Loader/formLoader";
import EditorInput from "../../Components/Input/EditorInput";

interface SectionErrors {
    [key: string]: {
        imageUrl?: string;
        title?: string;
        description?: string;
    };
}

const BlogSection: React.FC = () => {
    const dispatch = useDispatch();
    const Snackbar = useSnackbar();
    const [sections, setSections] = useState([{ id: 1, imageUrl: "", title: "", description: "" }]);
    const [editorStates, setEditorStates] = useState<{ [key: string]: string }>({});
    const [errors, setErrors] = useState<SectionErrors>({});
    const { blogDetails } = useSelector((state: any) => state.blogReducer);
    const { formLoader } = useSelector((state: any) => state.loaderReducer);

    useEffect(() => {
        if (blogDetails?.images && blogDetails.images.length > 0) {
            const transformedSections = blogDetails.images.map((img: any, index: number) => ({
                id: img.id || index + 1,
                imageUrl: img.imageUrl || "",
                title: img.title || "",
                description: img.description || ""
            }));
            setSections(transformedSections);
            
            // Initialize editor states with the actual content
            const initialEditorStates = {};
            transformedSections.forEach(section => {
                initialEditorStates[`title-${section.id}`] = section.title || "";
                initialEditorStates[`description-${section.id}`] = section.description || "";
            });
            setEditorStates(initialEditorStates);
        }
    }, [blogDetails]);

    const handleAddSection = () => {
        const newId = sections.length + 1;
        setSections([...sections, { id: newId, imageUrl: "", title: "", description: "" }]);
        setEditorStates(prev => ({
            ...prev,
            [`title-${newId}`]: "",
            [`description-${newId}`]: ""
        }));
    };

    const handleRemoveSection = (id: number) => {
        setSections(sections.filter((section) => section.id !== id));
        const newEditorStates = { ...editorStates };
        delete newEditorStates[`title-${id}`];
        delete newEditorStates[`description-${id}`];
        setEditorStates(newEditorStates);
        
        const newErrors = { ...errors };
        delete newErrors[id];
        setErrors(newErrors);
    };

    const handleChange = (index: number, field: string, value: string) => {
        const updatedSections = sections.map((section, i) =>
            i === index ? { ...section, [field]: value } : section
        );
        setSections(updatedSections);
        
        // Clear error when user makes changes
        if (errors[updatedSections[index].id]?.[field]) {
            setErrors(prev => ({
                ...prev,
                [updatedSections[index].id]: {
                    ...prev[updatedSections[index].id],
                    [field]: undefined
                }
            }));
        }
    };

    const handleEditorState = (sectionId: number, field: string, value: string) => {
        // Update editor state
        const editorField = `${field}-${sectionId}`;
        setEditorStates(prev => ({
            ...prev,
            [editorField]: value
        }));

        // Update section content
        setSections(prev => prev.map(section =>
            section.id === sectionId 
                ? { ...section, [field]: value } 
                : section
        ));

        // Clear error if any
        if (errors[sectionId]?.[field]) {
            setErrors(prev => ({
                ...prev,
                [sectionId]: {
                    ...prev[sectionId],
                    [field]: undefined
                }
            }));
        }
    };

    const handleBlurEditor = (sectionId: number, field: string) => {
        const editorContent = editorStates[`${field}-${sectionId}`] || "";
        
        // Create a div to strip HTML and check content
        const div = document.createElement("div");
        div.innerHTML = editorContent;
        const trimmedContent = div.textContent?.trim();

        // Update the section with either the content or empty string
        setSections(prev => prev.map(section =>
            section.id === sectionId
                ? { ...section, [field]: trimmedContent ? editorContent : "" }
                : section
        ));

        // Set error if empty
        if (!trimmedContent) {
            setErrors(prev => ({
                ...prev,
                [sectionId]: {
                    ...prev[sectionId],
                    [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`
                }
            }));
        }
    };

    const validateSection = (section: any) => {
        const sectionErrors: any = {};
        const titleDiv = document.createElement("div");
        titleDiv.innerHTML = section.title;
        if (!titleDiv.textContent?.trim()) {
            sectionErrors.title = "Title is required";
        }

        const descDiv = document.createElement("div");
        descDiv.innerHTML = section.description;
        if (!descDiv.textContent?.trim()) {
            sectionErrors.description = "Description is required";
        }

        return sectionErrors;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!blogDetails?.tdcblogId) {
            Snackbar.show("Blog ID not found!", "error");
            return;
        }

        const newErrors: SectionErrors = {};
        let hasErrors = false;

        sections.forEach(section => {
            const sectionErrors = validateSection(section);
            if (Object.keys(sectionErrors).length > 0) {
                newErrors[section.id] = sectionErrors;
                hasErrors = true;
            }
        });

        if (hasErrors) {
            setErrors(newErrors);
            Snackbar.show("Please fill in all required fields", "error");
            return;
        }

        const payload = {
            tdcblogId: blogDetails.tdcblogId,
            images: sections.map(section => ({
                imageUrl: section.imageUrl,
                title: section.title,
                description: section.description
            }))
        };

        try {
            const response: any = await dispatch(
                saveBlogImages(apiEndPoint.GET_BLOG_SAVEORUPDATEIMAGES, payload)
            );

            if (response.status) {
                Snackbar.show("Blog sections updated successfully!", "success");
            } else {
                Snackbar.show(response.result || "Failed to update blog sections", "error");
            }
        } catch (error) {
            console.error("Error saving blog images:", error);
            Snackbar.show("An error occurred while saving blog sections", "error");
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            style={{ flex: 1, padding: "10px", border: "1px solid #ccc", borderRadius: "5px", marginLeft: "20px", position: "relative" }}
        >
            <h3 style={{ display: "inline-block" }}>Blog Section</h3>

            <button
                type="button"
                onClick={handleAddSection}
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    background: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    fontSize: "20px",
                    cursor: "pointer",
                }}
            >
                +
            </button>

            {sections.map((section, index) => (
                <div key={section.id} style={{ marginBottom: "15px", borderBottom: "1px solid #ddd", paddingBottom: "10px", position: "relative" }}>
                    <h4>Section {index + 1}</h4>

                    {sections.length > 1 && (
                        <button
                            type="button"
                            onClick={() => handleRemoveSection(section.id)}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "5px",
                                background: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "25px",
                                height: "25px",
                                fontSize: "16px",
                                cursor: "pointer",
                            }}
                        >
                            -
                        </button>
                    )}

                    <input
                        type="text"
                        placeholder="Enter Image URL"
                        value={section.imageUrl}
                        onChange={(e) => handleChange(index, "imageUrl", e.target.value)}
                        style={{ 
                            width: "100%", 
                            padding: "8px", 
                            marginBottom: "10px", 
                            borderRadius: "5px", 
                            border: errors[section.id]?.imageUrl ? "1px solid red" : "1px solid #ccc" 
                        }}
                    />
                    {errors[section.id]?.imageUrl && (
                        <div style={{ color: "red", fontSize: "12px", marginTop: "-8px", marginBottom: "8px" }}>
                            {errors[section.id]?.imageUrl}
                        </div>
                    )}

                    <EditorInput
                        title={`Section ${index + 1} Title`}
                        field="title"
                        id={`title-editor-${section.id}`}
                        value={editorStates[`title-${section.id}`] || ""}
                        onBlur={() => handleBlurEditor(section.id, "title")}
                        handleDataChange={(_, value) => handleEditorState(section.id, "title", value)}
                        errorFound={!!errors[section.id]?.title}
                    />
                    {errors[section.id]?.title && (
                        <div style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>
                            {errors[section.id]?.title}
                        </div>
                    )}

                    <EditorInput
                        title={`Section ${index + 1} Description`}
                        field="description"
                        id={`description-editor-${section.id}`}
                        value={editorStates[`description-${section.id}`] || ""}
                        onBlur={() => handleBlurEditor(section.id, "description")}
                        handleDataChange={(_, value) => handleEditorState(section.id, "description", value)}
                        errorFound={!!errors[section.id]?.description}
                    />
                    {errors[section.id]?.description && (
                        <div style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>
                            {errors[section.id]?.description}
                        </div>
                    )}
                </div>
            ))}

            <SubmitBtn type="submit" disabled={formLoader} disabledColor={formLoader}>
                Update
            </SubmitBtn>

            <FormLoader visible={formLoader} />
        </form>
    );
};

export default BlogSection;