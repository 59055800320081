import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import { Delete, Edit } from "@material-ui/icons";
import { createTheme, darken, lighten } from "@mui/material/styles";

function getThemePaletteMode(palette) {
  return palette.type || palette.mode;
}
const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        "& .super-app-theme--Y": {
          backgroundColor: "#E9EBEE",
        },
        "& .super-app-theme--header": {
          backgroundColor: "#90A4AE",
          color: "#fff",
          fontWeight: "500",
          fontSize: 16,
        },
      },
    };
  },
  { defaultTheme }
);

interface Props {
  data: any;
  columns: any;
  handleEdit?: any;
  handleDelete?: any;
  handlePage?: any;
  page?: number;
  totalItems?: any;
  loading?: boolean;
}

const DataTable: React.FC<Props> = (props) => {
  let { data, columns, page, loading, totalItems } = props;
  const classes = useStyles();
  let updatedColumns: any = [];

  updatedColumns = columns.map((item, i) => {
    return {
      ...item,
      headerClassName: "super-app-theme--header",
    };
  });

  let action: any = {
    field: "actions",
    headerClassName: "super-app-theme--header",
    headerName: "Actions",
    sortable: false,
    flex: 0.3,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <div style={{ display: "flex", flex: 1 }}>
          <div
            style={{
              cursor: "pointer",
              flex: 0.5,
              justifyContent: "center",
              marginLeft: 10,
            }}
            onClick={() => handleEdit(params.row)}
          >
            <Edit style={{ marginBottom: -5, color: "gray" }} key={params.row.id} />
          </div>
          <div
            style={{ cursor: "pointer", flex: 0.5, justifyContent: "center" }}
            onClick={() => handleDelete(params.row)}
          >
            <Delete style={{ marginBottom: -5, color: "gray" }} key={params.row.id} />
          </div>
        </div>
      );
    },
  };

  updatedColumns = [...updatedColumns, { ...action }];

  const handleEdit = (item) => {
    props.handleEdit(item);
  };

  const handleDelete = (item) => {
    props.handleDelete(item);
  };

  const handlePage = (page) => {
    props.handlePage(page);
  };

  const handleDisableStyle = (params) => {
    let disabledCss = params.getValue(params.id, "disabled");
    if (!disabledCss) {
      disabledCss = params.getValue(params.id, "isDisabled");
    }
    return `super-app-theme--${disabledCss}`;
  };

  return (
    <div className={classes.root}>
      <div style={{ height: 600, width: "100%" }}>
        {data && data.length > 0 && (
          <>
            <DataGrid
              page={page}
              rows={data}
              rowCount={totalItems}
              columns={updatedColumns}
              pageSize={10}
              disableSelectionOnClick
              paginationMode="server"
              onPageChange={(newPage) => handlePage(newPage)}
              loading={loading}
              disableColumnMenu={true}
              getRowClassName={(params) => handleDisableStyle(params)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DataTable;
