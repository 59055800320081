import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@material-ui/core/styles";
import "./tab.css";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  tbdata: {
    height: "10vh",
  },
}));

interface Props {
  screen: string;
  tabs?: any;
  activeTab: any;
  selectableTab: any;
  handleChangeTab: any;
  configReducer?: any;
  courseReducer?: any;
}

const HeaderTab: React.FC<Props> = (props) => {
  let { screen } = props;
  const classes = useStyles();
  let { tabs, activeTab } = props;
  const { overViewVideos } = useSelector((state: Props) => state.courseReducer);
  let enableTab = overViewVideos && overViewVideos.length > 0;

  const handleChange = (event, newValue) => {
    // if (screen === "course" && newValue > 1 && !enableTab) {
    //   alert("Please Add Course Overview Video First!");
    //   return false;
    // }
    props.handleChangeTab(newValue);
  };

  let fullWidth: any = "100%";
  let tabWidth: any = tabs && tabs.length > 0 ? `${99 / tabs.length}%` : "50%";

  const LinkTab = (props) => {
    return (
      <Tab
        style={{ minWidth: tabWidth }}
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  };

  return (
    <Box sx={{ width: fullWidth }} className={classes.tbdata}>
      <Tabs value={activeTab} onChange={handleChange} aria-label="nav tabs example" style={{ width: "100%" }}>
        {tabs.map((item, i) => (
          <LinkTab label={item.label} tabNum={i} href="/trash" />
        ))}
      </Tabs>
    </Box>
  );
};

export default HeaderTab;
