import React from "react";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { customPhoneRegex } from "../../config/constant";

interface Props {
  handlePhoneInput?: any;
  phoneNumber?: any;
  disabled?: boolean;
  name?: string;
  customValidation?: boolean;
  placeholder?: string;
  style?: any;
  color?: any;
  colorType?: any;
}

const CustomPhoneInput: React.FC<Props> = (props) => {
  let { phoneNumber } = props;

  const handlePhoneNumber = (enteredPhone: any, { countryCode }: any, customEvent: any) => {
    let phoneRegex: any = customPhoneRegex[countryCode?.toUpperCase()];
    if (!phoneRegex) {
      phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    }
    let isValidPhone = false;
    if (phoneRegex.test(enteredPhone)) {
      isValidPhone = true;
    }
    props.handlePhoneInput(customEvent.target, phoneRegex, isValidPhone);
  };

  return (
    <div style={{ margin: "30px 0px 0px 0px" }}>
      <ReactPhoneInput
        disabled={props?.disabled || false}
        country={"in"}
        value={phoneNumber}
        onChange={(phoneNumber, phone, country) =>
          handlePhoneNumber(phone, country, { target: { value: phoneNumber, name: "phone" } })
        }
        countryCodeEditable={false}
        enableSearch={true}
        placeholder=""
        specialLabel=""
        inputStyle={{
          backgroundColor: "#FFFDF7",
          padding: "18px 50px",
          marginTop: "30px",
          width: "100%",
          display: "block",
          minWidth: "0px",
          background: "none",
          letterSpacing: "inherit",
          border: "1px solid #d3d3d3",
        }}
        inputProps={{ name: props?.name ? props?.name : "phone" }}
        autoFormat
      />
    </div>
  );
};

export default CustomPhoneInput;
