import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import React, { useEffect, useState, useCallback } from "react";

import { convertToHTML } from "draft-convert";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "./input.css";
import { FormLabel } from "@material-ui/core";

interface Props {
  title: string;
  value: string;
  field: string;
  id?: string;
  errorFound: boolean;
  onBlur: any;
  handleDataChange: any;
}

const EditorInput: React.FC<Props> = (props) => {
  let { title, value, field, id, errorFound } = props;

  // Initialize editor state with content
  const createEditorState = useCallback((htmlContent: string) => {
    if (!htmlContent) {
      return EditorState.createEmpty();
    }
    try {
      const sampleMarkup = `<div>${htmlContent}</div>`;
      const blocksFromHTML = convertFromHTML(sampleMarkup);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(state);
    } catch (error) {
      console.error('Error creating editor state:', error);
      return EditorState.createEmpty();
    }
  }, []);

  const [editorState, setEditorState] = useState(() => createEditorState(value));
  const [lastKnownValue, setLastKnownValue] = useState(value);

  // Convert editor content to HTML with proper entity preservation
  const convertEditorToHtml = useCallback((state: EditorState) => {
    try {
      return convertToHTML({
        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return `<a href="${entity.data.url}" target="${entity.data.target || '_blank'}">${originalText}</a>`;
          }
          return originalText;
        }
      })(state.getCurrentContent());
    } catch (error) {
      console.error('Error converting editor to HTML:', error);
      return '';
    }
  }, []);

  // Handle editor state changes
  const handleEditorStateChange = useCallback((newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const html = convertEditorToHtml(newEditorState);
    if (html !== lastKnownValue) {
      props.handleDataChange(field, html);
      setLastKnownValue(html);
    }
  }, [field, lastKnownValue, props.handleDataChange, convertEditorToHtml]);

  // Sync with external value changes
  useEffect(() => {
    if (value !== lastKnownValue) {
      setEditorState(createEditorState(value));
      setLastKnownValue(value);
    }
  }, [value, createEditorState, lastKnownValue]);

  const editorId = id || `editor-${field}`;

  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <FormLabel
        key={String(errorFound)}
        htmlFor={editorId}
        style={{ color: `${errorFound ? "#f44444" : "primary"}` }}
      >
        {title}
      </FormLabel>
      <div
        id={editorId}
        className="dtls-innr"
        style={{
          marginTop: 5,
          border: `1px solid ${errorFound ? "#f44444" : "#d8dbe0"}`,
          borderRadius: 5,
          minHeight: "250px",
          width: "100%",
        }}
      >
        <Editor 
          onBlur={props.onBlur} 
          editorState={editorState} 
          onEditorStateChange={handleEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
            inline: { inDropdown: false },
            link: {
              inDropdown: false,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_blank',
              options: ['link'],
              linkCallback: undefined,
            }
          }}
          toolbarId={`toolbar-${editorId}`}
          wrapperClassName={`wrapper-${editorId}`}
          editorClassName={`editor-${editorId}`}
        />
      </div>
    </div>
  );
};

export default React.memo(EditorInput);
