import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../Components/Input/CustomInput";
import EditorInput from "../../Components/Input/EditorInput";
import { getBlogDetails, getBlogsByCustomFilter, saveBlog } from "../../redux/action/blogAction";
import { SubmitBtn } from "../../Components/Card/Card";
import CustomSwitch from "../../Components/Switches/CustomSwitch";
import { enableHeaderTab } from "../../redux/action/configAction";
import { BLOG_TITLE_LENGTH, BLOG_SHORT_DETAILS_LENGTH, BLOG_DETAILS_LENGTH } from "../../config/textLength";
import FormLoader from "../../Components/Loader/formLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
  },
  input: {},
  submit: {
    color: "white",
    margin: theme.spacing(2, 2, 2),
  },
  form: {
    width: "100%",
  },
  textDanger: {
    color: "#ff4444",
    marginTop: -24,
    fontSize: 12,
    fontFamily: "Oxanium,cursive",
    marginLeft: 14,
    fontWeight: 400,
  },
  metaSection: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

interface props {
  url?: any;
  blogReducer?: any;
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
}

const initialState: any = {
  title: "",
  shortDetails: "",
  details: "",
  isDisabled: "N",
  picture: "",
  img: [],
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
};

const BlogForm: React.FC<props> = (props) => {
  const Snackbar = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState<any>(initialState);
  const { formLoader } = useSelector((state: any) => state.loaderReducer);
  let { id } = useParams();
  const { blogDetails, currentPage } = useSelector((state: props) => state.blogReducer);

  const validationSchema = yup.object({
    title: yup
      .string()
      .strict(false)
      .trim()
      .required("Please Enter Title!")
      .test(
        "len",
        `Characters Length should not be greater than ${BLOG_TITLE_LENGTH}`,
        (val: any) => val?.toString().length <= BLOG_TITLE_LENGTH
      ),
    shortDetails: yup
      .string()
      .required("Please Enter Short Details!")
      .strict(false)
      .trim()
      .test(
        "len",
        `HTML Content of Short Details should not be greater than ${BLOG_SHORT_DETAILS_LENGTH} characters`,
        (val: any) => val?.toString().length <= BLOG_SHORT_DETAILS_LENGTH
      ),
    details: yup.string().required("Please Enter Details!"),
    metaTitle: yup.string(),
    metaDescription: yup.string(),
    metaKeyword: yup.string(),
  });

  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_BLOG_DETAILS}=${id}`;
      dispatch(getBlogDetails(uri));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && blogDetails) {
      setInputField({
        tdcblogId: id,
        title: blogDetails.title || "",
        shortDetails: blogDetails.shortDetails || "",
        details: blogDetails.details || "",
        isDisabled: blogDetails.isDisabled || "N",
        picture: blogDetails.picture || "",
        img: blogDetails.images || [],
        metaTitle: blogDetails.metaTitle || "",
        metaDescription: blogDetails.metaDescription || "",
        metaKeyword: blogDetails.metaKeyword || "",
      });
    }
  }, [id, blogDetails]);

  const onSubmit = async () => {
    if (Object.keys(formik.errors).length !== 0) {
      return false;
    }
    addBlogData();
  };

  const addBlogData = async () => {
    const blogData = {
      tdcblogId: id ? id : 0,
      title: inputField.title,
      shortDetails: inputField.shortDetails,
      details: inputField.details,
      isDisabled: inputField.isDisabled,
      picture: inputField.picture,
      img: inputField.img,
      metaTitle: inputField.metaTitle,
      metaDescription: inputField.metaDescription,
      metaKeyword: inputField.metaKeyword,
    };

    let uri = `${apiEndPoint.SAVE_BLOG}`;
    let response: any = await dispatch(saveBlog(uri, blogData));
    if (response.status) {
      if (id) {
        history.push("/home/blog");
      } else {
        props.closeModal(false);
      }
      Snackbar.show(id ? "Updated Successfully" : "Save Successfully", "success");
      setTimeout(() => {
        if (!id && currentPage === 0) {
          fetchBlogList(currentPage, "");
        }
        dispatch(enableHeaderTab(true));
      }, 500);
    } else {
      Snackbar.show("Something Went Wrong.", "error");
    }
  };

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: inputField,
    validationSchema,
    onSubmit,
  });

  const handleInput = (name: string, value: any) => {
    setInputField(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditorChange = (field: string, value: string) => {
    handleInput(field, value);
  };

  const handleBlurEditor = (field: string) => {
    formik.setFieldTouched(field, true);
  };

  const handleDisabled = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const bool = e.target.checked ? "Y" : "N";
    handleInput(field, bool);
  };

  const fetchBlogList = (pageNumber: number, searchText: string) => {
    let uri = `${apiEndPoint.GET_BLOGS_BY_CUSTOM_FILTER}`;
    let args: any = {
      pageCriteria: {
        pageSize: 10,
        pageNum: pageNumber,
      },
    };
    if (searchText) {
      args = {
        ...args,
        filterCriteriaList: [
          {
            fieldName: "title",
            fieldOperator: "LIKE",
            fieldData: [`${searchText}%`],
          },
        ],
      };
    }
    dispatch(getBlogsByCustomFilter(uri, args, searchText));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    const { name } = e.target;
    handleInput(name, val.trim());
  };

  const classes = useStyles();

  return (
    <div>
      <FormLoader visible={formLoader} />
      <Grid container xs={12} sm={8} md={12} className={classes.root} justify="center">
        <form>
          <CustomSwitch
            field="isDisabled"
            handleStateChange={handleDisabled}
            checked={inputField?.isDisabled === "N" ? false : true}
            label="Disabled"
          />
          <CustomInput
            field="title"
            id="title"
            variant="outlined"
            required
            fullWidth
            className={classes.input}
            name="title"
            label="Enter Title"
            type="text"
            handleState={handleInput}
            onBlur={handleBlur}
            value={inputField.title}
            error={formik.errors.title}
            helperText={formik.errors.title}
          />
          <EditorInput
            title="Enter Short Details *"
            field="shortDetails"
            value={inputField.shortDetails}
            onBlur={() => handleBlurEditor("shortDetails")}
            errorFound={!!formik.errors.shortDetails}
            handleDataChange={handleEditorChange}
          />
          {formik.errors.shortDetails ? <div className={classes.textDanger}>{formik.errors.shortDetails}</div> : null}

          <EditorInput
            title="Enter All Details *"
            field="details"
            value={inputField.details}
            onBlur={() => handleBlurEditor("details")}
            errorFound={!!formik.errors.details}
            handleDataChange={handleEditorChange}
          />
          {formik.errors.details ? <div className={classes.textDanger}>{formik.errors.details}</div> : null}

          <div className={classes.metaSection}>
            <CustomInput
              field="metaTitle"
              id="metaTitle"
              variant="outlined"
              fullWidth
              className={classes.input}
              name="metaTitle"
              label="Meta Title (SEO)"
              type="text"
              handleState={handleInput}
              onBlur={handleBlur}
              value={inputField.metaTitle}
              error={formik.errors.metaTitle}
              helperText={formik.errors.metaTitle}
            />
            <CustomInput
              field="metaDescription"
              id="metaDescription"
              variant="outlined"
              fullWidth
              className={classes.input}
              name="metaDescription"
              label="Meta Description (SEO)"
              type="text"
              multiline
              rows={2}
              handleState={handleInput}
              onBlur={handleBlur}
              value={inputField.metaDescription}
              error={formik.errors.metaDescription}
              helperText={formik.errors.metaDescription}
            />
            <CustomInput
              field="metaKeyword"
              id="metaKeyword"
              variant="outlined"
              fullWidth
              className={classes.input}
              name="metaKeyword"
              label="Meta Keywords (SEO)"
              type="text"
              handleState={handleInput}
              onBlur={handleBlur}
              value={inputField.metaKeyword}
              error={formik.errors.metaKeyword}
              helperText={formik.errors.metaKeyword}
            />
          </div>

          <Grid>
            {id ? (
              <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                Update
              </SubmitBtn>
            ) : (
              <SubmitBtn disabled={formLoader} disabledColor={formLoader} onClick={formik.handleSubmit}>
                Submit
              </SubmitBtn>
            )}
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default BlogForm;