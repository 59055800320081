import "../Accordion/index.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SectionForm from "../../Page/Course/SectionForm";
import SubSectionForm from "../../Page/Course/SubSection";
import CustomizedDialogs from "../Modal/NewModal";
import { courseSectionTitle } from "../../redux/action/courseAction";
import FitbitIcon from "@mui/icons-material/Fitbit";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import { TiDocument, TiImage, TiVideo, TiBook } from "react-icons/ti";
import { MdNotes } from "react-icons/md";
interface AllProps {
  handleclick?: any;
  title?: string;
  id?: number;
  active?: number;
  content?: string;
  page?: string;
  iconName?: string;
  btnTitle?: any;
  rowData?: any;
  label: string;
  data: any;
  itemIndex?: any;
  activeSubSection?: any;
  sectionLength?: any;
  parentData?: any;
  extType?: string;
}
export const CustomAccordion: React.FC<AllProps> = (props) => {
  const dispatch = useDispatch();
  let {
    active,
    id,
    page,
    btnTitle,
    label,
    data,
    itemIndex,
    sectionLength,
    rowData,
    parentData,
  } = props;

  const handleSectionTitle = () => {
    if (props.active !== props.id && page === "sub-section") {
      let title = rowData.sectionTitle;
      dispatch(courseSectionTitle(title));
    } else {
      if (page === "sub-section") {
        dispatch(courseSectionTitle(null));
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(courseSectionTitle(null));
    };
  }, []);
  // console.log("tetet", props);
  return (
    <div className="tab" style={{ marginBottom: "20px" }}>
      <div
        className="title flex jc-btwn"
        onClick={() => {
          props.handleclick(id);
          handleSectionTitle();
        }}
      >
        <div style={{ alignItems: "center", display: "flex" }}>
          <span style={{ marginLeft: 10 }}>{props.title}</span>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ alignItems: "center", display: "flex" }}></div>
          {page === "content" ? (
            <div style={{ marginRight: 10 }}>
              {" "}
              {props.extType == "image" ? (
                <TiImage style={{fontSize:"1.5rem"}} />
              ) : props.extType == "video" ? (
                <TiVideo style={{fontSize:"1.5rem"}}/>
              ) : props.extType == "pdf" ? (
                <TiDocument style={{fontSize:"1.5rem"}}/>
              ) : props.extType == "csv" ? (
                <MdNotes style={{fontSize:"1.5rem"}}/>
              ) : props.extType == "exercise" ? (
                <TiBook style={{fontSize:"1.5rem"}}/>
              ): ("")}
            </div>
          ) : null}

          <CustomizedDialogs
            customeIcon={"edit"}
            page={
              page === "sub-section"
                ? "section"
                : props.page === "content"
                ? "sub-section"
                : "content"
            }
            title={btnTitle}
            rowData={rowData}
            label={label}
            data={data}
            itemIndex={itemIndex}
            sectionLength={sectionLength}
            parentData={parentData}
          ></CustomizedDialogs>
          <CustomizedDialogs
            customeIcon={"add"}
            page={page}
            title={btnTitle}
            rowData={null}
            label={page === "content" ? "Content" : label}
            data={data}
            itemIndex={itemIndex}
            sectionLength={sectionLength}
            parentData={parentData}
          ></CustomizedDialogs>
          {props.active === props.id ? (
            <span
              style={{
                cursor: "pointer",
                paddingLeft: "15px",
                paddingRight: "6px",
              }}
              className={"minus"}
            >
              <ArrowDropUpIcon></ArrowDropUpIcon>
            </span>
          ) : (
            <span
              style={{
                cursor: "pointer",
                paddingLeft: "15px",
                paddingRight: "6px",
              }}
              className={"plus"}
            >
              <ArrowDropDownIcon></ArrowDropDownIcon>
            </span>
          )}
        </div>
      </div>
      <div className={active === id ? "content show" : "content hide"}>
        {page === "sub-section" ? (
          <SectionForm rowData={rowData} type={"view"}></SectionForm>
        ) : page === "content" ? (
          <SubSectionForm
            activeIndex={props.id}
            rowData={rowData}
            data={data}
            parentData={parentData}
            type={"view"}
          ></SubSectionForm>
        ) : (
          ""
        )}
        {props.children}
      </div>
    </div>
  );
};
